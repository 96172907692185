

























import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import ClosedDateForm from '@/components/closed-dates/ClosedDateForm.vue';
import BaseClosedDateMixin from '@/components/closed-dates/BaseClosedDateMixin';
import Vue, { VueConstructor } from 'vue';
import ResourceMixin from '@/mixins/ResourceMixin';
import { mapStores } from 'pinia';
import { ClosedDatePanel, useUiStore } from '@/store/ui-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof ResourceMixin> &
    InstanceType<typeof BaseClosedDateMixin>
>).extend({
  components: {
    ClosedDateForm,
    BackButtonTitle,
    CheckEventsConflictsModal
  },
  mixins: [ResourceMixin, BaseClosedDateMixin],
  props: {
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapStores(useUiStore, useClosedDatesStore)
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(ClosedDatePanel.ADD_CLOSED_DATE);
    },
    async addClosedDate() {
      await this.closedDatesStore.create(this.closedDate);

      if (
        !this.sendingData &&
        Object.keys(this.validationErrors).length === 0
      ) {
        this.closeCurrentPanel();
      }
    }
  }
});
