

































import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import BaseResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import {
  CLOSE_DATE_PANELS_LAYOUT,
  ClosedDatePanel,
  useUiStore
} from '@/store/ui-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import { ClosedDate } from '@/model/closed-date';
import ConfigListElement from '@/components/shared/ConfigListElement.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseResourceConfigMixin>
>).extend({
  components: {
    EmptyDataset,
    LoadingIndicator,
    ConfigListElement
  },
  mixins: [BaseResourceConfigMixin],
  computed: {
    ...mapStores(useUiStore, useClosedDatesStore),
    isFetchingClosedDates(): boolean {
      return this.closedDatesStore.fetching;
    },
    closedDates(): ClosedDate[] {
      return this.closedDatesStore.entities;
    },
    currentClosedDate(): ClosedDate {
      return this.closedDatesStore.currentEntity;
    }
  },
  methods: {
    editClosedDate(closedDate) {
      this.uiStore.openPanel(
        CLOSE_DATE_PANELS_LAYOUT,
        ClosedDatePanel.EDIT_CLOSED_DATE
      );
      this.closedDatesStore.setCurrentEntity(closedDate);
    }
  }
});
