









































import DateInput from '@/components/shared/forms/DateInput.vue';
import MultiSelectInput from '@/components/shared/forms/MultiSelectInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import TextInput from '@/components/shared/forms/TextInput.vue';
import resourceService from '@/services/resource.service';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import BaseClosedDateMixin from '@/components/closed-dates/BaseClosedDateMixin';
import { Location } from '@/model/location';
import { useLocationsStore } from '@/store/locations-store';
import { ClosedDate } from '@/model/closed-date';
import ButtonGroup from '@/components/shared/ButtonGroup.vue';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof BaseClosedDateMixin>
>).extend({
  components: {
    DateInput,
    TextInput,
    MultiSelectInput,
    SubmitButton,
    ButtonGroup
  },
  mixins: [BaseClosedDateMixin],
  props: {
    closedDateToEdit: {
      type: Object,
      default: () => {}
    },
    withAssociations: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      closedDate: {} as Partial<ClosedDate>,
      associatedLocations: []
    };
  },
  computed: {
    ...mapStores(useUiStore, useClosedDatesStore, useLocationsStore),
    locations(): Location[] {
      return this.locationsStore.entities;
    }
  },
  watch: {
    closedDateToEdit() {
      this.fillExistingData();
    }
  },
  created() {
    this.fillExistingData();
  },
  methods: {
    fillExistingData() {
      this.closedDate = {
        id: this.closedDateToEdit ? this.closedDateToEdit.id : '',
        date: this.closedDateToEdit ? this.closedDateToEdit.date : '',
        summary: this.closedDateToEdit ? this.closedDateToEdit.summary : '',
        vendor_id: this.closedDateToEdit ? this.closedDateToEdit.vendor_id : ''
      };
      this.associatedLocations = this.closedDateToEdit
        ? this.closedDateToEdit.locations
        : [];
    },
    async updateDate(date) {
      this.closedDate.date = date;
      const closedDate = await resourceService.findAClosedDateByDate(date);
      if (closedDate) {
        this.closedDate = closedDate;
        if (closedDate.locations) {
          this.associatedLocations = closedDate.locations;
        }
      }
    },
    onSubmit() {
      this.$emit('onFormValidated', {
        closedDate: this.closedDate,
        locations: this.associatedLocations
      });
    }
  }
});
