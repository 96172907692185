
















































import ConfirmModal, {
  IConfirmModal
} from '@/components/shared/ConfirmModal.vue';
import BackButtonTitle from '@/components/shared/BackButtonTitle.vue';
import BaseClosedDateMixin from '@/components/closed-dates/BaseClosedDateMixin.ts';
import ResourceConfigMixin from '@/components/shared/ResourceConfigMixin';
import ClosedDateForm from '@/components/closed-dates/ClosedDateForm.vue';
import moment from 'moment';
import Vue, { VueConstructor } from 'vue';
import { mapStores } from 'pinia';
import { AlertType, ClosedDatePanel, useUiStore } from '@/store/ui-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import PlainButton from '@/components/shared/PlainButton.vue';
import CheckEventsConflictsModal from '@/components/timetables/CheckEventsConflictsModal.vue';

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof BaseClosedDateMixin> &
    InstanceType<typeof ResourceConfigMixin>
>).extend({
  components: {
    ConfirmModal,
    BackButtonTitle,
    ClosedDateForm,
    PlainButton,
    CheckEventsConflictsModal
  },
  mixins: [BaseClosedDateMixin, ResourceConfigMixin],
  data() {
    return {
      deleteClosedDateModal: {} as IConfirmModal
    };
  },
  computed: {
    ...mapStores(useUiStore, useClosedDatesStore)
  },
  mounted() {
    this.deleteClosedDateModal = (this.$refs
      .deleteClosedDateModal as unknown) as IConfirmModal;
  },
  methods: {
    closeCurrentPanel() {
      this.uiStore.closePanel(ClosedDatePanel.EDIT_CLOSED_DATE);
      this.closedDatesStore.unsetCurrentEntity();
      this.closedDatesStore.unsetValidationErrors();
    },
    openDeleteClosedDateModal() {
      this.deleteClosedDateModal.openModal(
        this.$t('confirmModal.deleteClosedDate.message', {
          date: moment(this.currentClosedDate.date).format('DD.MM.YYYY')
        }),
        {
          okTitle: this.$t('button.delete'),
          okVariant: 'danger'
        }
      );
    },
    async onConfirmClosedDateDeletion() {
      const closedDateSuccessfullyDeleted = await this.closedDatesStore.delete(
        this.currentClosedDate
      );
      this.deleteClosedDateModal.sendingData = false;
      if (closedDateSuccessfullyDeleted) {
        this.deleteClosedDateModal.closeModal();
        this.closeCurrentPanel();
        this.uiStore.alert(
          this.$t('toast.closedDateDeleted'),
          AlertType.SUCCESS
        );
      }
    },
    async editClosedDate() {
      await this.closedDatesStore.edit(this.closedDate);
    }
  }
});
