import { mapStores } from 'pinia';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import { ClosedDate } from '@/model/closed-date';
import Vue, { VueConstructor } from 'vue';
import { Collection } from '@/store/common/collections';
import ResourceMixin from '@/mixins/ResourceMixin';
import resourceService from '@/services/resource.service';
import { ConflictEventAction } from '@/constants';

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ResourceMixin>
>).extend({
  mixins: [ResourceMixin],
  data() {
    return {
      actions: [ConflictEventAction.CANCEL, ConflictEventAction.CHECK_DETAILS],
      closedDate: {} as ClosedDate
    };
  },
  computed: {
    ...mapStores(useClosedDatesStore),
    currentClosedDate(): ClosedDate {
      return this.closedDatesStore.currentEntity;
    },
    validationErrors(): {} {
      return this.closedDatesStore.validationErrors;
    },
    sendingData(): boolean {
      return this.closedDatesStore.saving;
    }
  },
  methods: {
    async onSaveClosedDate({ closedDate, locations }) {
      this.closedDate = this.closedDateForApi({ closedDate, locations });
      this.$bvModal.show('check-events-conflicts-modal-closed-date');
      this.closedDatesStore.$patch({
        validationErrors: {}
      });
    },
    async verifyClosedDateRequest() {
      await resourceService.verifyClosedDate(this.closedDate);
    },
    setValidationErrorsClosedDate(errors) {
      this.closedDatesStore.$patch({
        validationErrors: errors
      });
    },
    closedDateForApi({ closedDate, locations }) {
      const locationsForApi = this.getCollectionForApi(locations);

      closedDate = {
        ...closedDate,
        [Collection.LOCATIONS]: locationsForApi
      };

      return closedDate;
    }
  }
});
