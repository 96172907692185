





































import ClosedDatesList from '@/components/closed-dates/ClosedDatesList.vue';
import AddClosedDate from '@/components/closed-dates/AddClosedDate.vue';
import EditClosedDate from '@/components/closed-dates/EditClosedDate.vue';
import {
  CLOSE_DATE_PANELS_LAYOUT,
  ClosedDatePanel,
  useUiStore
} from '@/store/ui-store';
import { mapStores } from 'pinia/dist/pinia';
import { useUsersStore } from '@/store/users-store';
import { useClosedDatesStore } from '@/store/closed-dates-store';
import Vue from 'vue';
import { ClosedDate } from '@/model/closed-date';
import PlainButton from '@/components/shared/PlainButton.vue';

export default Vue.extend({
  components: {
    ClosedDatesList,
    AddClosedDate,
    EditClosedDate,
    PlainButton
  },
  data() {
    return {
      CLOSED_DATE_PANEL: ClosedDatePanel
    };
  },
  computed: {
    ...mapStores(useUiStore, useClosedDatesStore, useUsersStore),
    openedPanels(): string[] {
      return this.uiStore.openedPanels;
    },
    currentPanelIndex(): number {
      return this.uiStore.currentPanelIndex;
    },
    currentClosedDate(): ClosedDate {
      return this.closedDatesStore.currentEntity;
    },
    vendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    }
  },
  created() {
    this.closedDatesStore.findAll(null, true, false);
    this.uiStore.openPanel(
      CLOSE_DATE_PANELS_LAYOUT,
      ClosedDatePanel.CLOSED_DATE_LIST
    );
    if (this.currentClosedDate?.id) {
      this.uiStore.openPanel(
        CLOSE_DATE_PANELS_LAYOUT,
        ClosedDatePanel.EDIT_CLOSED_DATE
      );
    }
  },
  methods: {
    isPanelOpened(panel) {
      return this.uiStore.isPanelOpened(panel);
    },
    addClosedDate() {
      this.closedDatesStore.unsetCurrentEntity();
      this.uiStore.openPanel(
        CLOSE_DATE_PANELS_LAYOUT,
        ClosedDatePanel.ADD_CLOSED_DATE
      );
    }
  }
});
